import { Box, styled, Typography } from "@mui/material";

import { BodyText } from "@/components/body.text";
import { StyledButton } from "@/components/button";
import { WithID } from "@/models/shared";
import { Stage } from "@/models/stage";
import { StyleUtils } from "@/constants/style.utils";
import Tick from "@/assets/green-tick.svg";

import { StageTitle } from "./styles";

interface StageCardBodyProps {
  stage: WithID<Stage>;
  isSolved?: boolean;
}

export const StageCardBody: React.FC<StageCardBodyProps> = ({
  stage,
  isSolved = false,
}) => {
  return (
    <CardBodyWrapper py={3} px={2}>
      {/* Tick Image */}
      {isSolved && <TickImage src={Tick} alt="selected" />}

      <StageTitle component="h3">FRAGE {stage.stage_number}</StageTitle>

      <BodyText my={2.8}>{stage.question}</BodyText>

      {!isSolved && (
        <Box textAlign={"center"}>
          <StyledButton>BEANTWORTEN</StyledButton>
        </Box>
      )}

      {isSolved && (
        <Box textAlign={"center"}>
          <Typography
            color={"brand.green_1"}
            fontSize={StyleUtils.fonts.size(3.5)}
            fontWeight={600}
            letterSpacing="1px"
          >
            GELÖST
          </Typography>
        </Box>
      )}
    </CardBodyWrapper>
  );
};

const CardBodyWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 2),
  position: "relative",
}));

const TickImage = styled("img")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  borderRadius: "50%",
}));

export const StyleUtils = {
  border: {
    radius: (size = 4) => `${(size * 4) / 10}rem`,
  },
  fonts: {
    size: (size = 4) => `${(size * 4) / 10}rem`,
  },
  spacing: (size = 4) => `${(size * 4) / 10}rem`,
  stroke: (size = 1) => {
    return `-${size}px -${size}px 0 #000, ${size}px -${size}px 0 #000, -${size}px ${size}px 0 #000, ${size}px ${size}px 0 #000`;
  },
} as const;

import { alpha, Box, styled, Typography } from "@mui/material";
import { Impressum } from "@/components/impressum";
import { StyleUtils } from "@/constants/style.utils";

export const ContentWrapper = styled(Box)`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const LogoImage = styled("img")(({ theme }) => ({
  width: "12rem",
  display: "block",
  margin: theme.spacing(4.6, "auto"),
}));

export const EpisodeNumber = styled(Typography)(({ theme }) => ({
  fontFamily: "Anton",
  fontSize: StyleUtils.fonts.size(4),
  color: alpha(theme.palette.common.white, 0.5),
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
  letterSpacing: "0.4rem",
  // textShadow: StyleUtils.stroke(1),
})) as typeof Typography;

export const EpisodeName = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.primary,
  fontSize: StyleUtils.fonts.size(11),
  fontFamily: "Anton",
  textTransform: "uppercase",
  letterSpacing: "0.4rem",

  ".first-line": {
    fontSize: StyleUtils.fonts.size(9),
    color: alpha(theme.palette.common.white, 0.5),
    // textShadow: StyleUtils.stroke(1),
  },
})) as typeof Typography;

export const CrimeCasesImage = styled("img")({
  width: "100%",
  display: "block",
});

export const ImpressumCustomized = styled(Impressum)(({ theme }) => ({
  color: theme.palette.common.black,
  position: "absolute",
  top: "29rem",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 10,

  [theme.breakpoints.down(394)]: {
    top: "27rem",
  },
  [theme.breakpoints.down(391)]: {
    top: "27rem",
  },
  [theme.breakpoints.down(376)]: {
    top: "26rem",
  },
  [theme.breakpoints.down(361)]: {
    top: "25rem",
  },
  [theme.breakpoints.down(281)]: {
    top: "19rem",
  },
}));

export const CoverImageWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  position: "relative",
  minHeight: "37rem",
  [theme.breakpoints.down("mobile_wrapper")]: {
    minHeight: "33rem",
  },
}));

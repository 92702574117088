import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import { useGetUserLogs } from "@/firebase/logs/use.get.user.logs";
import { usePersons } from "@/firebase/persons/use.persons";
import { selectUserState } from "@/store/slices/user.slice";
import { selectCurrentStage } from "@/store/slices/stages.slice";
import { useAppSelector } from "@/hooks/common";
import { StyledButton } from "@/components/button";
import { AppModal, AppModalProps } from "@/components/modals/app.modal";
import { AppRoutes } from "@/constants/app.routes";

import { handleCorrectAnswer } from "../shared/handle.correct.answer";
import { handleIncorrectAnswer } from "../shared/handle.incorrect.answer";
import PersonCard from "./person.card";
import { ContentWrapper, PersonsContainer, QuestionText } from "./styles";

export const SelectPersonView: React.FC = () => {
  const { persons } = usePersons();
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [modal, setModal] = useState<AppModalProps>({
    open: false,
    title: "",
    titleColor: "green",
    body: "",
    buttonText: "",
    onClose: () => {
      setModal((prev) => ({
        ...prev,
        open: false,
      }));
    },
  });

  const user = useAppSelector(selectUserState).user!;
  const { userLogs } = useGetUserLogs(user.phone_number);
  const currentStage = useAppSelector(selectCurrentStage)!;
  const navigate = useNavigate();



  // TODO: hacky, not good code!
  // multiselet should be determined by length of correct answers
  // hintPartiallyCorrect should be fetch from firebase
  let multiselect = false;
  if (currentStage.answer_type === "select_person"){
    multiselect = Array.isArray(currentStage.answer) && currentStage.answer.length > 1;
  }


  let hintPartiallyCorrect = ""
  if (currentStage.hint_partially_correct) {
    hintPartiallyCorrect = currentStage.hint_partially_correct
  }


  const handleOnSelect = (selected: string) => {
    if (multiselect) {
      if (userAnswers.includes(selected)) {
        setUserAnswers(prev => prev.filter(id => id !== selected));
      } else {
        setUserAnswers(prev => [...prev, selected]);
      }
    } else {
      setUserAnswers([selected]);
    }
  };

  const onShowSolution = (answer: string | string[]) => {
    if (Array.isArray(answer)) { // if answer is an array
      const selectedPersonsIds = answer
          .map(ans =>
              persons.find(p => p.name.toLocaleLowerCase().trim() === ans.toLocaleLowerCase().trim())?.id
          )
          .filter(id => id !== undefined); // Explicitly filter out undefined values
      setUserAnswers(selectedPersonsIds as string[]);
    } else { // if answer is a string (original code)
      const selectedPerson = persons.find(
          (p) => p.name.toLocaleLowerCase().trim() === answer.toLocaleLowerCase().trim()
      );
      setUserAnswers(selectedPerson ? [selectedPerson.id] : []);
    }
  };



  const handleSubmitAnswer = () => {
    let isCorrect = false;
    let isPartiallyCorrect = false;

    if (currentStage.answer_type === "select_person") {
      if (multiselect) {
        const _userAnswers = userAnswers
            .map(id => persons.find(p => p.id === id)?.name)
            .filter(Boolean)
            .map(name => name!.toLocaleLowerCase());

        let correctAnswers: string[] = [];
        if (Array.isArray(currentStage.answer)) {
          correctAnswers = currentStage.answer.map(ans => ans.toLocaleLowerCase());
        }

        isCorrect = _userAnswers.length === correctAnswers.length &&
            _userAnswers.every(answer => correctAnswers.includes(answer));

        if (!isCorrect) {
          // Check if any answer is correct for partial correctness
          isPartiallyCorrect = _userAnswers.some(answer => correctAnswers.includes(answer));
        }

      } else {
        const _userAnswer = persons.find((p) => p.id === userAnswers[0])?.name;
        if (!_userAnswer) return;
        isCorrect = currentStage.answer[0].toLocaleLowerCase() === _userAnswer.toLocaleLowerCase();
      }

      if (isCorrect) {
        handleCorrectAnswer({
          solvedStage: currentStage,
          userAnswer: multiselect ? userAnswers.join(", ") : userAnswers[0],
          navigate,
          setModal,
        });
      } else if (isPartiallyCorrect) {
        setModal({
          open: true,
          title: "Teilweise richtig",
          titleColor: "orange",
          body: hintPartiallyCorrect,
          buttonText: "Weiter",
          onClose: () => {
            setModal(prev => ({
              ...prev,
              open: false
            }));
          }
        });
      } else {
        handleIncorrectAnswer({
          currentStage,
          userAnswer: multiselect ? userAnswers.join(", ") : userAnswers[0],
          userLogs,
          setModal,
          onShowSolution,
        });
      }
    }
  };




  return (
      <ContentWrapper>
        <QuestionText>
          {currentStage.stage_number}. {currentStage.question}
        </QuestionText>

        <PersonsContainer>
          {/* Map over persons */}
          {persons.map((person) => {
            return (
                <PersonCard
                    key={person.id}
                    person={person}
                    selected={userAnswers.includes(person.id) ? person.id : ""}
                    onSelect={handleOnSelect}
                />
            );
          })}
        </PersonsContainer>
        <Box sx={{ mt: 6.4, textAlign: "center" }}>
          <StyledButton
              variant="contained"
              disabled={userAnswers.length === 0}
              onClick={handleSubmitAnswer}
          >
            ANTWORT ÜBERPRÜFEN
          </StyledButton>
        </Box>

        <Box mt={2} textAlign="center">
          <StyledButton to={AppRoutes.select_stage} component={Link}>
            Abbrechen
          </StyledButton>
        </Box>

        {/* Modal */}
        <AppModal {...modal} />
      </ContentWrapper>
  );
};
import React, { ComponentProps } from "react";
import { StyleUtils } from "@/constants/style.utils";
import { alpha, Link, styled } from "@mui/material";

const StyledLink = styled(Link)(({ theme }) => ({
  lineHeight: "2rem",
  fontSize: StyleUtils.fonts.size(4),
  fontWeight: 400,
  color: alpha(theme.palette.common.white, 0.5),
  letterSpacing: "0.2rem",
}));

export const Impressum: React.FC<ComponentProps<typeof StyledLink>> = (
  props
) => {
  return (
    <StyledLink
      target="_blank"
      rel="noreferrer"
      href="https://link.crime-cases.de/impressum"
      underline="none"
      {...props}
    >
      Impressum
    </StyledLink>
  );
};

import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { MdLightbulb, MdNotifications, MdQuiz } from "react-icons/md";

import { AppRoutes } from "@/constants/app.routes";

export const BottomNavLayout: React.FC = () => {
  const naviagte = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState(routeToTab[location.pathname]);

  return (
    <>
      <Outlet />

      {/* Bottom Navigation */}
      <Paper
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={tab}
          onChange={(_, newValue) => {
            setTab(newValue);

            if (newValue === 2) {
              window.open(
                "https://hilfe.crime-cases.de/traumschiff-hinweise/",
                "_blank"
              );
              return;
            }

            naviagte(tabToRoute[newValue as keyof typeof tabToRoute], {
              replace: true,
            });
          }}
        >
          <BottomNavigationAction
            label="Nachrichten"
            icon={<MdNotifications />}
            sx={{
              fontSize: "2.8rem",
            }}
          />
          <BottomNavigationAction
            label="Fragen"
            icon={<MdQuiz />}
            sx={{
              fontSize: "2.8rem",
            }}
          />
          <BottomNavigationAction
            label="Hinweise"
            icon={<MdLightbulb />}
            sx={{
              fontSize: "2.8rem",
            }}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
};

const routeToTab = {
  [AppRoutes.messages]: 0,
  [AppRoutes.select_stage]: 1,
};

const tabToRoute = {
  0: AppRoutes.messages,
  1: AppRoutes.select_stage,
};

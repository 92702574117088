import React, { useEffect } from "react";
import { Box, Modal, TextField } from "@mui/material";

import { StyledButton } from "@/components/button";
import { StyleUtils } from "@/constants/style.utils";

import { ModalBody, ModalContent, ModalTitle } from "./shared";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (feedback: string) => void;
  initialFeedback: string;
}

// This modal is shown when the rating is 3 or below stars
export const Modal3OrBelow: React.FC<Props> = ({
  open,
  onClose,
  onSubmit,
  initialFeedback,
}) => {
  const [feedback, setFeedback] = React.useState(initialFeedback);

  useEffect(() => {
    setFeedback(initialFeedback);
  }, [initialFeedback]);

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <ModalContent>
          <Box mb={2}>
            <ModalTitle title={"JETZT SIND WIR NEUGIERIG"} titleColor={"red"} />
          </Box>

          <Box mb={2}>
            <ModalBody text={"Willst du uns etwas wissen lassen?"} />
          </Box>

          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Dein Feedback"
              InputLabelProps={{ shrink: true }}
              multiline
              rows={3}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              sx={{
                "& .MuiInputBase-input, & .MuiFormLabel-root, & .MuiOutlinedInput-notchedOutline":
                  {
                    fontSize: StyleUtils.fonts.size(3.5),
                  },
              }}
            />
          </Box>

          <Box mb={2}>
            <StyledButton
              onClick={() => {
                onClose();
                onSubmit(feedback);
              }}
            >
              Speichern
            </StyledButton>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  );
};

import React from "react";
import { alpha, Paper, styled, Typography } from "@mui/material";

import { TickImage } from "@/components/tick.image";
import { Person } from "@/models/person";
import { StyleUtils } from "@/constants/style.utils";

interface PersonCardProps {
  person: Person;
  selected: string;
  onSelect: (selected: string) => void;
}

const PersonCard: React.FC<PersonCardProps> = ({
  person,
  selected,
  onSelect,
}) => {
  return (
    <CardContainer
      sx={(theme) => ({
        backgroundColor:
          person.id === selected ? alpha(theme.palette.primary.main, 0.05) : "",
        border: `2px solid ${
          person.id === selected
            ? theme.palette.primary.main
            : theme.palette.grey["card"]
        }`,
      })}
      elevation={2}
      onClick={() => onSelect(person.id)}
    >
      {/* Show check when selected */}
      {person.id === selected && <TickImage />}
      <CardImage src={person.image} alt={person.name} />
      <Typography variant="h6" fontSize={StyleUtils.fonts.size(3.5)}>
        {person.name}
      </Typography>
    </CardContainer>
  );
};

export default PersonCard;

// Components
const CardContainer = styled(Paper)(({ theme }) => ({
  width: "160px",
  minHeight: "210px",
  padding: theme.spacing(1.5, 1.5, 1),
  textAlign: "center",
  cursor: "pointer",
  position: "relative",
}));

const CardImage = styled("img")(({ theme }) => ({
  width: "100%",
  display: "block",
  borderRadius: StyleUtils.border.radius(1),
  marginBottom: theme.spacing(2),
}));

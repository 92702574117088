import { Box, Modal } from "@mui/material";
import React from "react";

import { StyledButton } from "@/components/button";

import { ModalBody, ModalContent, ModalTitle } from "./shared";

interface Props {
  open: boolean;
  onClose: () => void;
}

// This modal is shown at the end of the rating process
export const ThankYouModal: React.FC<Props> = ({ open, onClose }) => {
  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <ModalContent>
          <Box mb={2}>
            <ModalTitle title={"VIELEN DANK!"} titleColor={"green"} />
          </Box>

          <Box mb={2}>
            <ModalBody
              text={"Wir werden versuchen dein Feedback umzusetzen."}
            />
          </Box>

          <Box mb={2}>
            <StyledButton onClick={onClose}>Spiel beenden</StyledButton>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  );
};

import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import { selectPhoneNumber } from "@/store/slices/local.storage.slice";
import { fetchUser, setUserAndLoading } from "@/store/slices/user.slice";
import { useAppDispatch, useAppSelector } from "@/hooks/common";
import { AppRoutes } from "@/constants/app.routes";

import { MobileLayout, BottomNavLayout } from "@/layouts";
import {
  Home,
  IntroductionAudio,
  PhoneNumberPage,
  SelectDifficulty,
  SelectStage,
  StageView,
  Messages,
  StagesComplete,
  Ratings,
} from "@/pages";
import {
  GuardUser,
  GuardSelectedStage,
  GuardStagesCompleted,
} from "@/components/guards.hoc";

function App() {
  const phoneNumber = useAppSelector(selectPhoneNumber);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (phoneNumber !== null) {
      dispatch(fetchUser(phoneNumber));
    } else {
      dispatch(
        setUserAndLoading({
          user: null,
          loading: "loaded",
        })
      );
    }
  }, [phoneNumber, dispatch]);

  return (
    <Routes>
      {/* Global Layout */}
      <Route element={<MobileLayout />}>
        <Route path={AppRoutes.home} element={<Home />} />

        {/* Pages which should be visited only if their is no user */}
        <Route element={<GuardUser userExist={false} />}>
          <Route
            path={AppRoutes.select_difficulty}
            element={<SelectDifficulty />}
          />
          <Route path={AppRoutes.phone_number} element={<PhoneNumberPage />} />
        </Route>

        {/* Page which needs user to exist */}
        <Route element={<GuardUser userExist />}>
          <Route
            path={AppRoutes.introduction_audio}
            element={<IntroductionAudio />}
          />

          {/* Pages with Bottom Navigation */}
          <Route element={<BottomNavLayout />}>
            <Route path={AppRoutes.select_stage} element={<SelectStage />} />
            <Route path={AppRoutes.messages} element={<Messages />} />

            {/* Pages which needs a stage to be selected */}
            <Route element={<GuardSelectedStage />}>
              <Route path={AppRoutes.stage} element={<StageView />} />
            </Route>

            {/* Pages which needs a all stages to be completed */}
            <Route element={<GuardStagesCompleted />}>
              <Route
                path={AppRoutes.stages_complete}
                element={<StagesComplete />}
              />
              <Route
                path={AppRoutes.ratings}
                element={<Ratings />}
              />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;

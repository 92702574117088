import { collection, doc, getDoc, setDoc } from "firebase/firestore";

import { Review } from "@/models/review";
import { PhoneNumber, WithID } from "@/models/shared";
import { Collections } from "@/constants/firebase.collections";

import { db } from "..";

// Reviews Collection Ref
const reviewsRef = collection(db, Collections.REVIEWS);

export const createReview = async (id: PhoneNumber, review: Review) => {
  const docRef = doc(reviewsRef, id);
  await setDoc(docRef, review, { merge: true });
};

export const getReview = async (id: PhoneNumber) => {
  const docRef = doc(reviewsRef, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data(), id: docSnap.id } as WithID<Review>;
  } else {
    return null;
  }
};

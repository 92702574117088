import React from "react";
import { Box, Modal } from "@mui/material";

import { setLogDoc } from "@/firebase/logs";
import { selectUserState } from "@/store/slices/user.slice";
import { useAppSelector } from "@/hooks/common";
import { StyledButton } from "@/components/button";
import { currentTimeInSeconds } from "@/utils/common";

import { ModalBody, ModalContent, ModalTitle } from "./shared";

interface Props {
  open: boolean;
  onClose: () => void;
}

// This modal is shown when the rating is above 3 stars
export const ModalAbove3: React.FC<Props> = ({ open, onClose }) => {
  const user = useAppSelector(selectUserState).user!;

  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <ModalContent>
          <Box mb={2}>
            <ModalTitle title={"VIELEN DANK!"} titleColor={"green"} />
          </Box>

          <Box mb={2}>
            <ModalBody
              text={
                "Wenn ihr möchtet könnt ihr die Bewertung auch auf Amazon posten, das würde uns sehr helfen. Falls nicht könnt ihr die Seite einfach schließen"
              }
            />
          </Box>

          <Box mb={2}>
            <StyledButton
              onClick={() => {
                onClose();
                setLogDoc(user.phone_number, {
                  type: "clicked_amazon_link",
                  timestamp: currentTimeInSeconds(),
                });
              }}
              component={"a"}
              href={
                "https://www.amazon.de/review/review-your-purchases/?asin=B0CBSYJ3DN"
              }
              target={"_blank"}
            >
              Zu Amazon
            </StyledButton>
          </Box>
        </ModalContent>
      </Modal>
    </div>
  );
};

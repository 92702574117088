import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, TextField } from "@mui/material";

import { useGetUserLogs } from "@/firebase/logs/use.get.user.logs";
import { selectUserState } from "@/store/slices/user.slice";
import { selectCurrentStage } from "@/store/slices/stages.slice";
import { useAppSelector } from "@/hooks/common";
import { StyledButton } from "@/components/button";
import { AppModal, AppModalProps } from "@/components/modals/app.modal";
import { AppRoutes } from "@/constants/app.routes";
import { StyleUtils } from "@/constants/style.utils";

import { handleCorrectAnswer } from "../shared/handle.correct.answer";
import { handleIncorrectAnswer } from "../shared/handle.incorrect.answer";
import { ContentWrapper, QuestionText } from "./styles";

export const TextInputView = () => {
  const [userAnswer, setUserAnswer] = useState("");
  const [modal, setModal] = useState<AppModalProps>({
    open: false,
    title: "",
    titleColor: "green",
    body: "",
    buttonText: "",
    onClose: () => {
      setModal((prev) => ({
        ...prev,
        open: false,
      }));
    },
  });

  const user = useAppSelector(selectUserState).user!;
  const { userLogs } = useGetUserLogs(user.phone_number);
  const currentStage = useAppSelector(selectCurrentStage)!;
  const navigate = useNavigate();

  const onShowSolution = (answer: string) => {
    setUserAnswer(answer);
  };

  const handleSubmitAnswer = () => {
    // Lowercase the answer and remove all whitespaces and special characters
    const answer = userAnswer.toLocaleLowerCase().replace(/[^a-z0-9]/gi, "");

    if (
      currentStage.answer_type === "text_input" && // For correct TS typings
      currentStage.answers.some((ans) =>
        answer.includes(ans.toLocaleLowerCase())
      )
    ) {
      // Handle correct answer
      handleCorrectAnswer({
        solvedStage: currentStage,
        userAnswer,
        navigate,
        setModal,
      });
    } else {
      // Handle incorrect answer
      handleIncorrectAnswer({
        currentStage,
        userAnswer,
        userLogs,
        setModal,
        onShowSolution,
      });
    }
  };

  return (
    <ContentWrapper>
      <QuestionText>
        {currentStage.stage_number}. {currentStage.question}
      </QuestionText>

      <Box my={6.4}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Gebt hier eure Antwort ein"
          InputProps={{
            sx: {
              fontSize: StyleUtils.fonts.size(3.5),
            },
          }}
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
        />
      </Box>
      <Box sx={{ mt: 6.4, textAlign: "center" }}>
        <StyledButton
          variant="contained"
          disabled={userAnswer === ""}
          onClick={handleSubmitAnswer}
        >
          ANTWORT ÜBERPRÜFEN
        </StyledButton>
      </Box>

      <Box mt={2} textAlign="center">
        <StyledButton to={AppRoutes.select_stage} component={Link}>
          Abbrechen
        </StyledButton>
      </Box>

      {/* Modal */}
      <AppModal {...modal} />
    </ContentWrapper>
  );
};

import { styled, Button } from "@mui/material";
import { StyleUtils } from "@/constants/style.utils";

export const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: StyleUtils.fonts.size(3.5),
  fontWeight: 500,
  padding: theme.spacing(1, 1.5),
  textTransform: "uppercase",
  lineHeight: "1.6rem",
  letterSpacing: "0.125rem",
})) as typeof Button;

import { Box, styled } from "@mui/material";

export const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
}));

export const CardsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  columnGap: theme.spacing(3.2),
  width: "fit-content",
  height: "100%",
  padding: theme.spacing(0, 1),
}));

import { useEffect, useState } from "react";

import { MessageList } from "@/models/mailbox";
import { LoadingState, PhoneNumber } from "@/models/shared";

import { getAllMessagesForUser } from ".";

export const useMessages = (phone_number: PhoneNumber) => {
  const [messages, setMessages] = useState<MessageList>([]);
  const [loading, setLoading] = useState<LoadingState>("idle");

  useEffect(() => {
    setLoading("loading");
    getAllMessagesForUser(phone_number)
      .then((messages) => {
        setMessages(messages);
        setLoading("loaded");
      })
      .catch(() => setLoading("failed"));
  }, [phone_number]);

  return { messages, loading };
};

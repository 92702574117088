import { NavigateFunction } from "react-router-dom";

import { updateUserDoc } from "@/firebase/users";
import { setLogDoc } from "@/firebase/logs";
import { store } from "@/store";
import { updateUser } from "@/store/slices/user.slice";
import { StageWithID } from "@/models/stage";
import { Log } from "@/models/log";
import { AppModalProps } from "@/components/modals/app.modal";
import { currentTimeInSeconds } from "@/utils/common";
import { AppRoutes } from "@/constants/app.routes";

interface HandleCorrectAnswerParams {
  solvedStage: StageWithID;
  userAnswer: string;
  navigate: NavigateFunction;
  setModal: React.Dispatch<React.SetStateAction<AppModalProps>>;
}

export const handleCorrectAnswer = ({
  solvedStage,
  userAnswer,
  navigate,
  setModal,
}: HandleCorrectAnswerParams) => {
  // Get data from Redux Store
  const user = store.getState().user.data!;
  const allStages = store.getState().stages.all;

  const solvedStages = [...user.solved_stages, solvedStage.stage_number];
  const completedGame = solvedStages.length >= allStages.length;

  updateUserDoc(user.phone_number, {
    solved_stages: solvedStages,
    completed_game: completedGame,
  });

  // Create a log for solved stage in the DB
  const solvedStageLog: Log = {
    type: "solved_stage",
    timestamp: currentTimeInSeconds(),
    stage_number: solvedStage.stage_number,
    answer: userAnswer,
  };

  // Save the log in the DB
  setLogDoc(user.phone_number, solvedStageLog);

  // Create a log for completed_game in the DB
  if (completedGame) {
    const completedGameLog: Log = {
      type: "completed_game",
      timestamp: currentTimeInSeconds(),
    };

    // Save the log in the DB
    setLogDoc(user.phone_number, completedGameLog);
  }

  // update local state data:
  store.dispatch(
    updateUser({
      solved_stages: solvedStages,
      completed_game: completedGame,
    })
  );

  // show feedback_text on correct answer
  setModal((prev) => ({
    ...prev,
    open: true,
    title: "RICHTIG",
    body: solvedStage.feedback_text,
    buttonText: "Weiter",
    titleColor: "green",
    onClose: () => {
      navigate(AppRoutes.select_stage, { replace: true });
    },
  }));
};

import { collection, getDocs, orderBy, query } from "firebase/firestore";

import { Stage, StageList } from "@/models/stage";
import { Collections } from "@/constants/firebase.collections";

import { db } from "..";

const stagesRef = collection(db, Collections.STAGES);

export const getAllStagesData = async (): Promise<StageList> => {
  const q = query(stagesRef, orderBy("stage_number", "asc"));
  const data = await getDocs(q);
  return data.docs.map((doc) => ({ ...(doc.data() as Stage), id: doc.id }));
};

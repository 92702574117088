import germany from "@/assets/+49.svg";
import austria from "@/assets/+43.png";
import switzerland from "@/assets/+41.png";
import luxemburg from "@/assets/+352.png";

export type CountryCode = "+49" | "+41" | "+43" | "+352";

export interface PhoneNumberState {
  number: string;
  country_code: CountryCode;
  isValid: boolean | undefined;
}

export const countries: { code: CountryCode; flag: string }[] = [
  { code: "+49", flag: germany },
  { code: "+41", flag: switzerland },
  { code: "+43", flag: austria },
  { code: "+352", flag: luxemburg },
];

import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getUserDoc } from "@/firebase/users";
import { RootState } from "@/store";
import { DBUserOrNull, UserWithPhoneNumber } from "@/models/user";
import { LoadingState, PhoneNumber } from "@/models/shared";
import { Stage } from "@/models/stage";

// Types
interface UserState {
  data: DBUserOrNull;
  loading: LoadingState;
}

// Thunks
// Fetch User
export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (id: PhoneNumber) => {
    return await getUserDoc(id);
  }
);

// Initial State
const initialState: UserState = {
  data: null,
  loading: "idle",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserAndLoading: (
      state,
      action: PayloadAction<{ user: DBUserOrNull; loading: LoadingState }>
    ) => {
      state.data = action.payload.user;
      state.loading = action.payload.loading;
    },

    setUser: (state, action: PayloadAction<DBUserOrNull>) => {
      state.data = action.payload;
    },

    updateUser: (
      state,
      action: PayloadAction<Partial<UserWithPhoneNumber>>
    ) => {
      state.data = {
        ...state.data,
        ...(action.payload as UserWithPhoneNumber),
      };
    },

    incrementHintsUsed: (
      state,
      action: PayloadAction<{ stage_number: Stage["stage_number"] }>
    ) => {
      const { stage_number } = action.payload;

      if (!state.data) {
        return;
      }

      if (state.data.hints_used) {
        state.data.hints_used[`stage_${stage_number}`] =
          (state.data.hints_used[`stage_${stage_number}`] ?? 0) + 1;
      } else {
        state.data.hints_used = {
          [`stage_${stage_number}`]: 1,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = "loaded";
      })
      .addCase(fetchUser.rejected, (state) => {
        state.data = null;
        state.loading = "failed";
      });
  },
});

// Action creators
export const { setUserAndLoading, setUser, updateUser, incrementHintsUsed } =
  userSlice.actions;

// Selectors
export const selectUserState = (state: RootState) => ({
  user: state.user.data,
  loading: state.user.loading,
});

// Reducer
export default userSlice.reducer;

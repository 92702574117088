import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

import { selectUserState } from "@/store/slices/user.slice";
import { useAppSelector } from "@/hooks/common";
import { AppRoutes } from "@/constants/app.routes";

interface GuardUserProps {
  userExist: boolean;
}

export const GuardUser: React.FC<GuardUserProps> = ({
  userExist, // if this is true then we expect that user must exist, else we check user is null
}) => {
  const userState = useAppSelector(selectUserState);

  if (userState.loading === "loading" || userState.loading === "idle") {
    return (
      <>
        <Box mt={9.4} textAlign="center">
          <CircularProgress />
        </Box>
      </>
    );
  }

  // If User is null (i.e. does not exists in redux store), and loading is loaded or failed then redirect
  if (userExist && userState.user === null) {
    return <Navigate to={AppRoutes.phone_number} replace />;
  }

  if (!userExist && userState.user) {
    return <Navigate to={AppRoutes.introduction_audio} replace />;
  }

  return <Outlet />;
};

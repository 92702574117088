import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { selectUserState } from "@/store/slices/user.slice";
import { setCurrentStage } from "@/store/slices/stages.slice";
import { useAppDispatch, useAppSelector } from "@/hooks/common";
import { WithID } from "@/models/shared";
import { Stage } from "@/models/stage";
import { AppRoutes } from "@/constants/app.routes";

import { StageCardBody } from "./stage.card.body";
import { StageCardWrapper } from "./styles";

interface StageCardProps {
  stage: WithID<Stage>;
}

const StageCard: React.FC<StageCardProps> = ({ stage }) => {
  const user = useAppSelector(selectUserState).user!;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <StageCardWrapper>
      {user.solved_stages.includes(stage.stage_number) ? (
        <StageCardBody stage={stage} isSolved />
      ) : (
        <>
          <Box
            sx={{
              textDecoration: "none",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(setCurrentStage(stage));
              navigate(AppRoutes.stage);
            }}
          >
            <StageCardBody stage={stage} />
          </Box>
        </>
      )}
    </StageCardWrapper>
  );
};

export default StageCard;

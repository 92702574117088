import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { selectUserState } from "@/store/slices/user.slice";
import { useAppSelector } from "@/hooks/common";
import { AppRoutes } from "@/constants/app.routes";

export const GuardStagesCompleted: React.FC = () => {
  const userState = useAppSelector(selectUserState);

  if (userState.user && !userState.user.completed_game) {
    return <Navigate to={AppRoutes.select_stage} replace />;
  }

  return <Outlet />;
};

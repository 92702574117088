import { Paper, styled, Typography } from "@mui/material";

import { StyleUtils } from "@/constants/style.utils";

export const StageCardWrapper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey.card,
})) as typeof Typography;

export const StageTitle = styled(Typography)(({ theme }) => ({
  fontSize: StyleUtils.fonts.size(3.5),
  textAlign: "center",
  fontWeight: 500,
  color: theme.palette.common.white,
})) as typeof Typography;

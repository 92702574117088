import { Box, styled } from "@mui/material";
import { MdCheckCircle } from "react-icons/md";

const TickImageBase = styled(MdCheckCircle)(({ theme }) => ({
  fill: theme.palette.primary.main,
  display: "block",
  width: "100%",
  height: "100%",
  transform: "scale(1.2)",
}));

const TickImageContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  width: "2.2rem",
  height: "2.2rem",
  backgroundColor: theme.palette.common.white,
  borderRadius: "50%",
  transform: "scale(0.8)",
}));

export const TickImage = () => {
  return (
    <TickImageContainer>
      <TickImageBase />
    </TickImageContainer>
  );
};

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ImageMap } from "@qiuz/react-image-map";

import { useGetUserLogs } from "@/firebase/logs/use.get.user.logs";
import { selectUserState } from "@/store/slices/user.slice";
import { selectCurrentStage } from "@/store/slices/stages.slice";
import { useAppSelector } from "@/hooks/common";
import { StyledButton } from "@/components/button";
import { AppModal, AppModalProps } from "@/components/modals/app.modal";
import stage3MapImage from "@/assets/stage-3-map.png";
import { AppRoutes } from "@/constants/app.routes";

import { handleCorrectAnswer } from "../shared/handle.correct.answer";
import { handleIncorrectAnswer } from "../shared/handle.incorrect.answer";
import { mapArea } from "./image.map.config";
import { ContentWrapper, QuestionText } from "./styles";

export const MapView = () => {
  const [selectedPin, setSelectedPin] = useState<number>();
  const [modal, setModal] = useState<AppModalProps>({
    open: false,
    title: "",
    titleColor: "green",
    body: "",
    buttonText: "",
    onClose: () => {
      setModal((prev) => ({
        ...prev,
        open: false,
      }));
    },
  });

  const mapAreaMemoized = React.useMemo(() => {
    const copiedMapArea = mapArea.map((area) => ({ ...area }));

    // Selected area
    const selectedArea = selectedPin
      ? copiedMapArea.find((area) => area.place_name === selectedPin)
      : null;

    if (selectedArea) {
      selectedArea.style = {
        ...selectedArea.style,
        color: "#38b6ff",
      };
    }

    return copiedMapArea;
  }, [selectedPin]);

  const user = useAppSelector(selectUserState).user!;
  const { userLogs } = useGetUserLogs(user.phone_number);
  const currentStage = useAppSelector(selectCurrentStage)!;
  const navigate = useNavigate();

  const onShowSolution = () => {
    setSelectedPin(1);
  };

  const handleSubmitAnswer = () => {
    if (
      currentStage.answer_type === "map" && // For correct TS typings
      selectedPin != null
    ) {
      const _userAnswer =
        selectedPin === 1 ? currentStage.answer : selectedPin.toString();

      if (_userAnswer === currentStage.answer) {
        // Handle correct answer
        handleCorrectAnswer({
          solvedStage: currentStage,
          userAnswer: _userAnswer,
          navigate,
          setModal,
        });
      } else {
        // Handle incorrect answer
        handleIncorrectAnswer({
          currentStage,
          userAnswer: _userAnswer,
          userLogs,
          setModal,
          onShowSolution,
        });
      }
    }
  };

  return (
    <ContentWrapper>
      <QuestionText>
        {currentStage.stage_number}. {currentStage.question}
      </QuestionText>

      <Box my={6.4}>
        <ImageMap
          className="usage-map"
          style={{ borderRadius: "4px", overflow: "hidden" }}
          src={stage3MapImage}
          map={mapAreaMemoized}
          onMapClick={(area) => {
            setSelectedPin(area.place_name);
          }}
        />
      </Box>
      <Box sx={{ mt: 6.4, textAlign: "center" }}>
        <StyledButton
          variant="contained"
          disabled={!selectedPin}
          onClick={handleSubmitAnswer}
        >
          ANTWORT ÜBERPRÜFEN
        </StyledButton>
      </Box>

      <Box mt={2} textAlign="center">
        <StyledButton to={AppRoutes.select_stage} component={Link}>
          Abbrechen
        </StyledButton>
      </Box>

      {/* Modal */}
      <AppModal {...modal} />
    </ContentWrapper>
  );
};

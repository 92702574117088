import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { RootState } from "@/store";
import {
  SelectedDifficultyLocalStorage,
  PhoneNumberLocalStorage,
} from "@/models/shared";

// Types
interface LocalStorageState {
  phoneNumber: PhoneNumberLocalStorage;
  selectedDifficulty: SelectedDifficultyLocalStorage;
}

// Initial State
const initialState: LocalStorageState = {
  phoneNumber: null,
  selectedDifficulty: null,
};

export const localStorageSlice = createSlice({
  name: "localStorage",
  initialState,
  reducers: {
    setLocalStorage: (state, action: PayloadAction<LocalStorageState>) => {
      state.phoneNumber = action.payload.phoneNumber;
      state.selectedDifficulty = action.payload.selectedDifficulty;
    },
    setPhoneNumber: (
      state,
      action: PayloadAction<Pick<LocalStorageState, "phoneNumber">>
    ) => {
      state.phoneNumber = action.payload.phoneNumber;
    },
    setSelectedDifficulty: (
      state,
      action: PayloadAction<{
        selectedDifficulty: SelectedDifficultyLocalStorage;
      }>
    ) => {
      state.selectedDifficulty = action.payload.selectedDifficulty;
    },
  },
});

// Action creators
export const { setPhoneNumber, setSelectedDifficulty, setLocalStorage } =
  localStorageSlice.actions;

// Selectors
export const selectPhoneNumber = (state: RootState) =>
  state.localStorage.phoneNumber;
export const selectSelectedDifficulty = (state: RootState) =>
  state.localStorage.selectedDifficulty;

// Reducer
const persistConfig = {
  key: "localStorage",
  storage,
};

export default persistReducer(persistConfig, localStorageSlice.reducer);

import React from "react";
import { styled } from "@mui/material";

import Cover from "@/assets/police-cover.svg";

const StyledCoverImage = styled("img")(({ theme }) => ({
  display: "block",
  margin: theme.spacing(0, "auto"),
  width: "100%",
}));

export const CoverImage = () => {
  return <StyledCoverImage src={Cover} alt="Polizei Gretenburg Logo" />;
};

import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { selectUserState } from "@/store/slices/user.slice";
import { selectCurrentStage } from "@/store/slices/stages.slice";
import { useAppSelector } from "@/hooks/common";
import { AppRoutes } from "@/constants/app.routes";

export const GuardSelectedStage: React.FC = () => {
  const user = useAppSelector(selectUserState).user!;
  const currentStage = useAppSelector(selectCurrentStage);

  // If user has completed all stages, then redirect to stages complete page
  if (user.completed_game) {
    return <Navigate to={AppRoutes.stages_complete} />;
  }

  // If currentStage is null (i.e. does not exists in redux store), then redirect
  if (currentStage === null) {
    return <Navigate to={AppRoutes.select_stage} />;
  }

  return <Outlet />;
};

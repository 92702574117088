import { alpha, Box, styled, Typography } from "@mui/material";
import { StyleUtils } from "@/constants/style.utils";

interface ContentWrapperProps {
  isSelected: boolean;
}

export const ContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<ContentWrapperProps>(({ theme, isSelected }) => ({
  position: "relative",
  width: "25rem",
  borderRadius: StyleUtils.border.radius(1),
  backgroundColor: isSelected
    ? alpha(theme.palette.primary.main, 0.08)
    : theme.palette.grey.card,
  border: isSelected ? `0.3rem solid ${theme.palette.primary.main}` : "none",
  padding: isSelected ? theme.spacing(2.1) : theme.spacing(2.4),
  boxShadow: theme.shadows[2],
  cursor: "pointer",
}));

export const DifficultyImage = styled("img")(({ theme }) => ({
  display: "block",
  width: "10rem",
  margin: theme.spacing(2.4, "auto"),
}));

export const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: StyleUtils.fonts.size(4),
  color: alpha(theme.palette.text.primary, 0.87),
  textAlign: "center",
})) as typeof Typography;

import React from "react";

import { incrementHintsUsedInUserDoc } from "@/firebase/users";
import { setLogDoc } from "@/firebase/logs";
import { store } from "@/store";
import { incrementHintsUsed } from "@/store/slices/user.slice";
import { StageWithID } from "@/models/stage";
import { Log, LogDoc } from "@/models/log";
import { SelectedDifficulty, PhoneNumber } from "@/models/shared";
import { AppModalProps } from "@/components/modals/app.modal";
import { currentTimeInSeconds } from "@/utils/common";

// Last number is the solution threshold in all difficulty levels
const errorsThresholds: Record<SelectedDifficulty, number[]> = {
  easy: [1, 2, 3, 4],
  medium: [2, 3, 4, 5],
  hard: [2, 4, 6, 8],
};

interface HandleIncorrectAnswerParams {
  currentStage: StageWithID;
  userAnswer: string;
  userLogs: LogDoc | null;
  setModal: React.Dispatch<React.SetStateAction<AppModalProps>>;
  onShowSolution: (answer: string) => void;
}

export const handleIncorrectAnswer = ({
  currentStage,
  userAnswer,
  userLogs,
  setModal,
  onShowSolution,
}: HandleIncorrectAnswerParams) => {
  // Get data from Redux Store
  const user = store.getState().user.data!;

  // userLogs should not be null as we are creating this document on user creation
  if (
    userLogs &&
    (currentStage.answer_type === "select_person" ||
      currentStage.answer_type === "text_input" ||
      currentStage.answer_type === "map") // For correct TS typings
  ) {
    // Get the count of curent stage failed attempts
    const currentStageFailsCount = userLogs.failed_stages?.filter(
      (log) => log.stage_number === currentStage.stage_number
    ).length
      ? userLogs.failed_stages?.filter(
          (log) => log.stage_number === currentStage.stage_number
        ).length + 1
      : 1; // 1 is used because we are counting the current attempt as failed

    // Get the number of hints used by the user
    const currentStageHintsUsed =
      user.hints_used?.[`stage_${currentStage.stage_number}`] ?? 0;

    // Hints thresholds for the current stage
    const hintsThresholds = errorsThresholds[user.selected_difficulty].slice(
      0,
      -1
    ); // -1 because last hint is the solution

    // Solution threshold for the current stage
    const solutionThreshhold =
      errorsThresholds[user.selected_difficulty].slice(-1)[0];

    // Total count of Hints + Solution for the current stage
    const maxHintsPlusSolution =
      errorsThresholds[user.selected_difficulty].length;

    // If the user has used all the hints, then show the solution
    if (
      currentStageFailsCount >= solutionThreshhold &&
      currentStageHintsUsed + 1 >= maxHintsPlusSolution
    ) {
      // Show solution
      setModal((prev) => ({
        ...prev,
        open: true,
        title: `LÖSUNG`,
        body: currentStage.hints[currentStageHintsUsed - 1],
        buttonText: "Lösung anzeigen",
        titleColor: "green",
        onClose: () => {
          onShowSolution(
            currentStage.answer_type === "text_input"
              ? currentStage.answers[0]
              : currentStage.answer
          );
          setModal((prev) => ({
            ...prev,
            open: false,
          }));
        },
      }));

      // Only increment hints_used in DB and Redux if the user has not used all the hints
      if (currentStageHintsUsed < maxHintsPlusSolution) {
        // Increment hints used in DB and Redux
        incrementHintsUsedInDBAndRedux(
          user.phone_number,
          currentStage.stage_number
        );
      }
    } else if (
      currentStageFailsCount >= Math.min(...hintsThresholds) &&
      currentStageHintsUsed < hintsThresholds.length &&
      currentStageFailsCount >= hintsThresholds[currentStageHintsUsed]
    ) {
      // Show hint if threshold is met
      setModal((prev) => ({
        ...prev,
        open: true,
        title: `HINWEIS ${currentStageHintsUsed + 1}`,
        body: currentStage.hints[currentStageHintsUsed],
        buttonText: "ZURÜCK",
        titleColor: "primary",
        onClose: () => {
          setModal((prev) => ({
            ...prev,
            open: false,
          }));
        },
      }));

      // Increment hints used in DB and Redux
      incrementHintsUsedInDBAndRedux(
        user.phone_number,
        currentStage.stage_number
      );
    } else {
      // Show generic hint/message if threshold is not met
      setModal((prev) => ({
        ...prev,
        open: true,
        title: "DANEBEN",
        body: currentStage.wrong_answer_text,
        buttonText: "ZURÜCK",
        titleColor: "red",
        onClose: () => {
          setModal((prev) => ({
            ...prev,
            open: false,
          }));
        },
      }));
    }
  }

  // Create a log for wrong_answer - the order matters, log should be created after the hint is shown
  const failedStageLog: Log = {
    type: "failed_stage",
    timestamp: currentTimeInSeconds(),
    stage_number: currentStage.stage_number,
    wrong_answer: userAnswer,
  };

  // Save the log in the DB
  setLogDoc(user.phone_number, failedStageLog);
};

// Increment hints used in DB and Redux
const incrementHintsUsedInDBAndRedux = (
  phone_number: PhoneNumber,
  stage_number: number
) => {
  // Update hints_used in the redux
  store.dispatch(incrementHintsUsed({ stage_number }));

  // Update hints_used in the user document in db
  incrementHintsUsedInUserDoc(phone_number, {
    stage_number,
  });
};

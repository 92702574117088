import { collection, getDocs } from "firebase/firestore";

import { Person, PersonList } from "@/models/person";
import { Collections } from "@/constants/firebase.collections";

import { db } from "..";

const personsRef = collection(db, Collections.PERSONS);

export const getAllPersons = async (): Promise<PersonList> => {
  const snapshot = await getDocs(personsRef);
  return snapshot.docs.map((doc) => ({
    ...(doc.data() as Person),
    id: doc.id,
  }));
};

import { collection, getDocs } from "firebase/firestore";

import { Message, MessageList } from "@/models/mailbox";
import { PhoneNumber } from "@/models/shared";
import { Collections } from "@/constants/firebase.collections";

import { db } from "..";

const mailboxRef = collection(db, Collections.MAILBOX);

export const getAllMessagesForUser = async (
  phone_number: PhoneNumber
): Promise<MessageList> => {
  const snapshot = await getDocs(
    collection(mailboxRef, phone_number, "messages")
  );
  return snapshot.docs.map((doc) => ({
    ...(doc.data() as Message),
    id: doc.id,
  }));
};

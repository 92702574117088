import React, { useState } from "react";
import { useNavigate } from "react-router-dom";  // Added useNavigate here
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";

import { selectUserState } from "@/store/slices/user.slice";
import { useAppSelector } from "@/hooks/common";
import { StyledButton } from "@/components/button";
import logo from "@/assets/homepage-logo.png";
import crimeCasesCover from "@/assets/crime-cases-cover.png";
import { AppRoutes } from "@/constants/app.routes";

import {
  CrimeCasesImage,
  ContentWrapper,
  LogoImage,
  EpisodeNumber,
  EpisodeName,
  ImpressumCustomized,
  CoverImageWrapper,
} from "./styles";


const SHOW_BETA_WARNING = false; // Set to false to disable the beta warning modal




export const Home = () => {
    const navigate = useNavigate();  // Added this line
    const user = useAppSelector(selectUserState).user;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        if (SHOW_BETA_WARNING) {
            setIsModalOpen(true);
        } else {
            handleStartGame();
        }
    };

    const handleStartGame = () => {
        const route = user === null ? AppRoutes.select_difficulty : AppRoutes.introduction_audio;
        navigate(route);  // This should work now
    };

  const handleCloseModal = () => {
      setIsModalOpen(false);
  };

  return (
    <ContentWrapper>
      <EpisodeNumber component="h3">003</EpisodeNumber>

      <LogoImage src={logo} alt="Crime Cases" />

      <EpisodeName component="h1">
        <span className="first-line">Tatort</span>
        <br />
        Traumschiff
      </EpisodeName>

      <Box
        sx={(theme) => ({
          margin: theme.spacing(4.6, 0),
          textAlign: "center",
        })}
      >
          <StyledButton
              onClick={handleOpenModal}  // Updated this line to open the modal on click
              variant="contained"
          >
              Spiel Starten
          </StyledButton>
      </Box>





        <Dialog
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
                {"Spiel in Beta Stadium"}
            </DialogTitle>

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Das Spiel wird am 31.10 veröffentlicht. Bis dahin sind bestimmte technische Inhalte eventuell noch nicht verfügbar.
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCloseModal} color="primary">
                    Zurück
                </Button>

                <Button onClick={handleStartGame} color="primary" autoFocus>
                    Trotzdem spielen
                </Button>
            </DialogActions>
        </Dialog>






        <CoverImageWrapper>
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            bottom: 0,
          }}
        >
          <ImpressumCustomized />
          <CrimeCasesImage src={crimeCasesCover} alt="Crime Cases Cover" />
        </Box>
      </CoverImageWrapper>
    </ContentWrapper>
  );
};

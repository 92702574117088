import React from "react";
import { Link } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";

import { selectUserState } from "@/store/slices/user.slice";
import { useGetAllStages } from "@/firebase/stages/use.get.all.stages";
import { useAppSelector } from "@/hooks/common";
import { BodyText } from "@/components/body.text";
import { Impressum } from "@/components/impressum";
import { CoverImage } from "@/components/cover.image";
import { StyledButton } from "@/components/button";
import { AppRoutes } from "@/constants/app.routes";

import StageCard from "./stage.card";
import { ContentWrapper, PoliceServerCredentials } from "./styles";

export const SelectStage: React.FC = () => {
  const user = useAppSelector(selectUserState).user!;
  const { stages, loading: stagesLoading } = useGetAllStages();

  return (
    <ContentWrapper>
      {/* Stages are loaded from DB */}
      {stagesLoading === "loaded" && (
        <>
          <CoverImage />

          <Box my={3.2}>
            {stages.map((stage) => (
              <Box my={3.2} key={stage.id}>
                <StageCard stage={stage} />
              </Box>
            ))}
          </Box>

          {user.completed_game && (
            <Box mb={3.2} textAlign="center">
              <StyledButton
                component={Link}
                to={AppRoutes.stages_complete}
                variant="contained"
              >
                Zum Abschluss Audio
              </StyledButton>
            </Box>
          )}

          <Box mb={3.2} textAlign="center">
            <Impressum />
          </Box>

          <PoliceServerCredentials>
            <h3>Login Polizei Server</h3>
            <p>
              Nutzer: <strong>{user.cc_police_server_username}</strong>
            </p>
            <p>
              Passwort: <strong>{user.cc_police_server_password}</strong>
            </p>
            <p>
              Adresse:{" "}
              <a
                href="https://polizei.gretenburg.de"
                target="_blank"
                rel="noreferrer noopener"
                css={{ color: "inherit" }}
              >
                <strong>polizei.gretenburg.de</strong>
              </a>
            </p>
          </PoliceServerCredentials>
        </>
      )}


      {/* TODO: Remove this once all posters have been exchanged */}

        {/*
       <Box p={4} textAlign="center">
        <Typography color='#9b9b9b'>
        Hinweis: Versehentlich ist auf manchen Verdächtigen-Postern Daniel Gussmann 2x abgedruckt.
        Für den Fall hat das keine Bedeutung.
        </Typography>

      </Box>
        */}


      {/* Stages are loading from DB */}
      {stagesLoading === "loading" && (
        <Box mt={6.4} textAlign="center">
          <CircularProgress />
        </Box>
      )}

      {/* Some Error occurred while loading Stages from DB */}
      {stagesLoading === "failed" && (
        <BodyText mt={6.4}>Oops! Something went wrong</BodyText>
      )}
    </ContentWrapper>
  );
};

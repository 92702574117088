import React, { useState } from "react";

import { getOrSetUserWithPhoneNumber } from "@/firebase/users";
import {
  selectSelectedDifficulty,
  setLocalStorage,
} from "@/store/slices/local.storage.slice";
import { useAppDispatch, useAppSelector } from "@/hooks/common";
import { PhoneNumber } from "@/models/shared";
import { UserWithPhoneNumber } from "@/models/user";
import { AppModal } from "@/components/modals/app.modal";
import { PageTitle } from "@/components/page.title";
import { BodyText } from "@/components/body.text";
import PhoneIcon from "@/assets/phone.png";

import PhoneNumberForm from "./phone.number.form";
import { ContentWrapper, PhoneImage } from "./styles";

export const PhoneNumberPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fetchedUser, setFetchedUser] = useState<UserWithPhoneNumber | null>(
    null
  );
  const selectedDifficulty = useAppSelector(selectSelectedDifficulty);
  const dispatch = useAppDispatch();

  const handlePhoneNumberSubmit = async (phoneNumber: PhoneNumber) => {
    // We are sure that phone number is set, so either we will get the already created user or create new one
    // we call the cloud function for triggering the phone calls
    const user = (await getOrSetUserWithPhoneNumber(
      phoneNumber,
      selectedDifficulty
    ))!;

    // Set user in component state
    setFetchedUser(user);

    setIsModalOpen(true);
  };

  //  We are sure that this will only be called after fetchedUser is set in state inside handlePhoneNumberSubmit, so fetchedUser is not null
  const handleCloseModal = async () => {
    // Set Phone Number in local storage and Update Selected Difficulty in local storage from the DB
    // This will cause the re-render of App Component and automatically redirect to the introduction_audio page and modal will be closed
    dispatch(
      setLocalStorage({
        phoneNumber: fetchedUser!.phone_number,
        selectedDifficulty: fetchedUser!.selected_difficulty,
      })
    );
  };

  return (
    <ContentWrapper>
      <PageTitle>GIB DEINE NUMMER EIN</PageTitle>

      <PhoneImage src={PhoneIcon} alt="phone" />

      <BodyText>
        Wie kann der Kommissar dich erreichen? Er hat Tipps für dich!
      </BodyText>

      <PhoneNumberForm onSubmit={handlePhoneNumberSubmit} />

      <AppModal
        title="HINWEIS"
        body="Bitte lasst das Handy während des Spiels in eurer Nähe und achtet
          darauf, dass es nicht stumm geschalten ist oder sich im Flugmodus
          befindet."
        buttonText="Weiter"
        open={isModalOpen}
        onClose={handleCloseModal}
      />
    </ContentWrapper>
  );
};

import React from "react";
import { Paper, Typography } from "@mui/material";
import moment from "moment";
import ReactAudioPlayer from "react-audio-player";

import { BodyText } from "@/components/body.text";
import type { MessageWithID } from "@/models/mailbox";
import { StyleUtils } from "@/constants/style.utils";

import { NotificationIcon } from "../styles";

interface MessageProps {
  message: MessageWithID;
}

const SingleMessage: React.FC<MessageProps> = ({ message }) => {
  return (
    <Paper
      key={message.id}
      sx={(theme) => ({
        backgroundColor: theme.palette.grey.card,
        margin: theme.spacing(2, 0),
        padding: theme.spacing(1.6),
        position: "relative",
      })}
    >
      {!message.read && (
        <NotificationIcon
          sx={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            width: "2rem",
            height: "2rem",
            fill: "#03DAC5",
          }}
        />
      )}

      <Typography
        sx={{
          textAlign: "center",
          fontSize: StyleUtils.fonts.size(5),
          mb: 1,
          fontWeight: 500,
          textTransform: "uppercase",
          letterSpacing: 0.15,
          maxWidth: "95%",
        }}
      >
        {message.title}
      </Typography>

      <BodyText
        sx={{
          fontSize: StyleUtils.fonts.size(2.5),
          letterSpacing: 2,
          textTransform: "uppercase",
          fontWeight: 500,
          mb: 0.4,
        }}
      >
        {message.sender}
      </BodyText>

      <BodyText
        sx={{
          fontSize: StyleUtils.fonts.size(2.5),
          letterSpacing: 2,
          textTransform: "uppercase",
          fontWeight: 500,
          mb: 1,
        }}
      >
        {moment(message.timestamp * 1000).format("HH:mm, DD.MM.YYYY")}
      </BodyText>

      {message.type === "text" && (
        <BodyText
          sx={{
            textAlign: "left",
            padding: 1,
            border: "2px solid currentColor",
            borderRadius: 1,
            whiteSpace: "pre-line",
          }}
        >
          {message.text_body}
        </BodyText>
      )}

      {message.type === "audio" && (
        <ReactAudioPlayer
          src={message.media_url}
          controls
          controlsList="nodownload"
          css={{
            height: "30px",
          }}
        />
      )}
    </Paper>
  );
};

export default SingleMessage;

import { Box, styled } from "@mui/material";
import { MdNotifications } from "react-icons/md";

export const ContentWrapper = styled(Box)(({ theme }) => ({
  maxWidth: "36.4rem",
  padding: theme.spacing(3.2, 1),
  margin: theme.spacing(0, "auto"),
  minHeight: "100%",
}));

export const NotificationIcon = styled(MdNotifications)(({ theme }) => ({
  display: "block",
  margin: theme.spacing(0, "auto", 1),
  fill: theme.palette.primary.main,
  width: "4.8rem",
  height: "4.8rem",
}));

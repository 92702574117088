import { Area } from "@qiuz/react-image-map";
import { IoLocationSharp } from "react-icons/io5";

declare module "@qiuz/react-image-map" {
  interface Area {
    place_name: number;
  }
}

// NOTE: keep the answer as the first object in the array
export const mapArea: Area[] = [
  {
    place_name: 1,
    width: "36px",
    height: "36px",
    left: "71%",
    top: "10.5%",
    style: { cursor: "pointer", color: "#ee1f44" },
    render: () => {
      return <IoLocationSharp size={36} fill="currentcolor" />;
    },
  },
  {
    place_name: 2,
    left: "15%",
    top: "14%",
    height: "36px",
    width: "36px",
    style: { cursor: "pointer", color: "#ee1f44" },
    render: () => {
      return <IoLocationSharp size={36} fill="currentcolor" />;
    },
  },
  {
    place_name: 3,
    width: "36px",
    height: "36px",
    left: "0%",
    top: "37%",
    style: { cursor: "pointer", color: "#ee1f44" },
    render: () => {
      return <IoLocationSharp size={36} fill="currentcolor" />;
    },
  },
  {
    place_name: 4,
    width: "36px",
    height: "36px",
    left: "84%",
    top: "26%",
    style: { cursor: "pointer", color: "#ee1f44" },
    render: () => {
      return <IoLocationSharp size={36} fill="currentcolor" />;
    },
  },
  {
    place_name: 5,
    width: "36px",
    height: "36px",
    left: "40%",
    top: "60%",
    style: { cursor: "pointer", color: "#ee1f44" },
    render: () => {
      return <IoLocationSharp size={36} fill="currentcolor" />;
    },
  },
  {
    place_name: 6,
    width: "36px",
    height: "36px",
    left: "14%",
    top: "80%",
    style: { cursor: "pointer", color: "#ee1f44" },
    render: () => {
      return <IoLocationSharp size={36} fill="currentcolor" />;
    },
  },
  {
    place_name: 7,
    width: "36px",
    height: "36px",
    left: "66%",
    top: "67%",
    style: { cursor: "pointer", color: "#ee1f44" },
    render: () => {
      return <IoLocationSharp size={36} fill="currentcolor" />;
    },
  },
];

import { SelectedDifficulty } from "@/models/shared";
import Difficulty1 from "@/assets/difficulty-1.png";
import Difficulty2 from "@/assets/difficulty-2.png";
import Difficulty3 from "@/assets/difficulty-3.png";

export interface Difficulty {
  level: SelectedDifficulty;
  title: string;
  body: string;
  image: string;
}

export const difficulties: Difficulty[] = [
  {
    level: "easy",
    title: "EINSTEIGER",
    body: "Keine Panik, der Kommissar hilft an den schwierigen Stellen weiter",
    image: Difficulty1,
  },
  {
    level: "medium",
    title: "MITTEL",
    body: "Du bekommst zwar Tipps aber ein paar schwierige Rätsel musst du selbst lösen",
    image: Difficulty2,
  },
  {
    level: "hard",
    title: "PROFI",
    body: "Für erfahrene Ermittler. Es gibt hier ein paar wirklich harte Nüsse!",
    image: Difficulty3,
  },
];

import { collection, doc, getDoc, setDoc } from "firebase/firestore";

import { Rating } from "@/models/rating";
import { PhoneNumber, WithID } from "@/models/shared";
import { currentTimeInSeconds } from "@/utils/common";
import { Collections } from "@/constants/firebase.collections";

import { setLogDoc } from "../logs";
import { db } from "..";

// Ratings Collection Ref
const ratingsRef = collection(db, Collections.RATINGS);

export const createRating = async (id: PhoneNumber, rating: Rating) => {
  setLogDoc(id, {
    type: "rated_game",
    timestamp: currentTimeInSeconds(),
  });

  const docRef = doc(ratingsRef, id);
  await setDoc(
    docRef,
    {
      stars: rating.stars,
    },
    { merge: true }
  );
};

export const getRating = async (id: PhoneNumber) => {
  const docRef = doc(ratingsRef, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data(), id: docSnap.id } as WithID<Rating>;
  } else {
    return null;
  }
};

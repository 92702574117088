import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "@/store";
import { StageList, StageWithIDOrNull } from "@/models/stage";

// Types
interface StagesState {
  current: StageWithIDOrNull;
  all: StageList;
}

// Initial State
const initialState: StagesState = {
  current: null,
  all: [],
};

export const stagesSlice = createSlice({
  name: "stages",
  initialState,
  reducers: {
    setAllStages: (state, action: PayloadAction<StageList>) => {
      state.all = action.payload;
    },
    setCurrentStage: (state, action: PayloadAction<StageWithIDOrNull>) => {
      state.current = action.payload;
    },
  },
});

// Action creators
export const { setCurrentStage, setAllStages } = stagesSlice.actions;

// Selectors
export const selectCurrentStage = (state: RootState) => state.stages.current;
export const selectAllStages = (state: RootState) => state.stages.all;

// Reducer
export default stagesSlice.reducer;

import { useEffect, useState } from "react";

import { LoadingState, PhoneNumber } from "@/models/shared";
import { LogDoc } from "@/models/log";

import { getLogDocOnSnapshot } from ".";

export const useGetUserLogs = (phone_number: PhoneNumber) => {
  const [userLogs, setUserLogs] = useState<LogDoc | null>(null);
  const [loading, setLoading] = useState<LoadingState>("idle");

  useEffect(() => {
    setLoading("loading");
    getLogDocOnSnapshot(
      phone_number,
      (snapshot) => {
        if (snapshot.exists()) {
          setUserLogs(snapshot.data() as LogDoc);
        } else {
          setUserLogs(null);
        }
        setLoading("loaded");
      },
      () => {
        setUserLogs(null);
        setLoading("failed");
      }
    );
  }, [phone_number]);

  return { userLogs, loading };
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Rating as RatingComponent } from "@mui/material";
import { MdStar, MdStarOutline } from "react-icons/md";

import { createRating, getRating } from "@/firebase/ratings";
import { createReview, getReview } from "@/firebase/reviews";
import { selectUserState } from "@/store/slices/user.slice";
import { useAppSelector } from "@/hooks/common";
import { CoverImage } from "@/components/cover.image";
import { Impressum } from "@/components/impressum";
import { PageTitle } from "@/components/page.title";
import { StyledButton } from "@/components/button";
import { ModalAbove3 } from "@/components/modals/modal.above.3";
import { Modal3OrBelow } from "@/components/modals/modal.3.or.below";
import { ThankYouModal } from "@/components/modals/thank.you.modal";

import { ModalType } from "./types";
import { ContentWrapper } from "./styles";

export const Ratings: React.FC = () => {
  const user = useAppSelector(selectUserState).user!;
  const [starsCount, setStarsCount] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [modal, setModal] = useState<ModalType>("none");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      // Get previous rating by user
      try {
        const rating = await getRating(user.phone_number);
        if (rating) {
          setStarsCount(rating.stars);
        } else {
          setStarsCount(0);
        }
      } catch {
        setStarsCount(0);
      }
    })();
  }, [user.phone_number]);

  useEffect(() => {
    (async () => {
      // Get previous review by user
      try {
        const review = await getReview(user.phone_number);
        if (review) {
          setFeedback(review.feedback);
        } else {
          setFeedback("");
        }
      } catch {
        setFeedback("");
      }
    })();
  }, [user.phone_number]);

  const handleSubmitRating = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      await createRating(user.phone_number, {
        stars: starsCount,
      });

      if (starsCount > 3) {
        // If rating is above 3, show modal to give public feedback on amazon
        setModal("above_3");
      } else {
        // check if starsCount is 3 or lower -> then we want to get an internal review by the user
        setModal("3_or_below");
      }
    } catch {
      console.log("Something went wrong");
    }
  };

  const handleSubmitReview = async (feedback: string) => {
    setFeedback(feedback);

    try {
      await createReview(user.phone_number, {
        feedback,
      });
      setModal("thank_you");
    } catch {
      console.log("Something went wrong");
    }
  };

  return (
    <ContentWrapper>
      <CoverImage />

      <Box mt={8}>
        <PageTitle>WIE GUT HAT EUCH DAS SPIEL GEFALLEN?</PageTitle>
      </Box>

      <Box mt={8} display={"flex"} justifyContent={"center"}>
        <RatingComponent
          name="rating"
          value={starsCount}
          onChange={(_event, newValue) => {
            setStarsCount(newValue ?? 0);
          }}
          sx={(theme) => ({
            color: theme.palette.primary.main,
          })}
          size="large"
          icon={
            <MdStar
              css={{
                width: "3.2rem",
                height: "3.2rem",
              }}
            />
          }
          emptyIcon={
            <MdStarOutline
              css={{
                width: "3.2rem",
                height: "3.2rem",
              }}
            />
          }
        />
      </Box>

      {/* Modals */}
      <ModalAbove3
        open={modal === "above_3"}
        onClose={() => {
          setModal("none");
        }}
      />

      <Modal3OrBelow
        open={modal === "3_or_below"}
        onClose={() => {
          setModal("none");
        }}
        onSubmit={handleSubmitReview}
        initialFeedback={feedback}
      />

      <ThankYouModal
        open={modal === "thank_you"}
        onClose={() => {
          setModal("none");
          navigate("/");
        }}
      />

      <Box mt={8} textAlign={"center"}>
        <StyledButton
          onClick={!!starsCount ? handleSubmitRating : undefined}
          disabled={!starsCount}
          variant="contained"
        >
          Weiter
        </StyledButton>
      </Box>

      <Box mt={8} mb={3.2} textAlign="center">
        <Impressum />
      </Box>
    </ContentWrapper>
  );
};

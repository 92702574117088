import { Box, CircularProgress } from "@mui/material";

import { useMessages } from "@/firebase/mailbox/use.messages";
import { selectUserState } from "@/store/slices/user.slice";
import { useAppSelector } from "@/hooks/common";
import { BodyText } from "@/components/body.text";
import { PageTitle } from "@/components/page.title";

import SingleMessage from "./single.message";
import { ContentWrapper, NotificationIcon } from "./styles";

export const Messages = () => {
  const user = useAppSelector(selectUserState).user!;
  const { messages, loading } = useMessages(user.phone_number);

  return (
    <ContentWrapper>
      {/* Messages are loaded from DB */}
      {loading === "loaded" && (
        <>
          <NotificationIcon />
          <PageTitle>NACHRICHTEN</PageTitle>

          {/* Messages here */}
          {messages.map((message) => (
            <SingleMessage key={message.id} message={message} />
          ))}
        </>
      )}

      {/* Messages are loading from DB */}
      {loading === "loading" && (
        <Box mt={6.4} textAlign="center">
          <CircularProgress />
        </Box>
      )}

      {/* Some Error occurred while loading Messages from DB */}
      {loading === "failed" && (
        <BodyText mt={6.4}>Oops! Something went wrong</BodyText>
      )}
    </ContentWrapper>
  );
};

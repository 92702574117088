import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// TODO: should be changed for testing
const firebaseConfig = {
  apiKey: "AIzaSyD1OOUBAZObo4AiriDP9suBTcNrU_1MEZ4",
  authDomain: "ks-03-start-game.firebaseapp.com",
  projectId: "ks-03-start-game",
  storageBucket: "ks-03-start-game.appspot.com",
  messagingSenderId: "671417247089",
  appId: "1:671417247089:web:9226715c41e488090202d0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const functions = getFunctions(app);

import {
  arrayUnion,
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  FirestoreError,
  onSnapshot,
  setDoc,
} from "firebase/firestore";

import { PhoneNumber } from "@/models/shared";
import { Log } from "@/models/log";
import { Collections } from "@/constants/firebase.collections";

import { db } from "..";

// Logs Collection Ref
const logsRef = collection(db, Collections.LOGS);

export const setLogDoc = async (id: PhoneNumber, data: Log) => {
  const logDoc = doc(logsRef, id);

  if (data.type === "started_game") {
    await setDoc(logDoc, { started_game: data }, { merge: true });
  }

  if (data.type === "completed_game") {
    await setDoc(logDoc, { completed_game: data }, { merge: true });
  }

  if (data.type === "finished_outro_audio") {
    await setDoc(logDoc, { finished_outro_audio: data }, { merge: true });
  }

  if (data.type === "rated_game") {
    await setDoc(logDoc, { rated_game: data }, { merge: true });
  }

  if (data.type === "clicked_amazon_link") {
    await setDoc(logDoc, { clicked_amazon_link: data }, { merge: true });
  }

  if (data.type === "solved_stage") {
    await setDoc(logDoc, { solved_stages: arrayUnion(data) }, { merge: true });
  }

  if (data.type === "failed_stage") {
    await setDoc(logDoc, { failed_stages: arrayUnion(data) }, { merge: true });
  }
};

export const getLogDocOnSnapshot = (
  phone_number: PhoneNumber,
  onNext: (snapshot: DocumentSnapshot<DocumentData>) => void,
  onError?: (error: FirestoreError) => void
) => {
  const logDoc = doc(logsRef, phone_number);

  return onSnapshot(logDoc, onNext, onError);
};

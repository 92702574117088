import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Box, styled } from "@mui/material";
import ReactAudioPlayer from "react-audio-player";

import { PageTitle } from "@/components/page.title";
import { BodyText } from "@/components/body.text";
import { StyledButton } from "@/components/button";
import { AppRoutes } from "@/constants/app.routes";
import { audios } from "@/constants/audios";

export const IntroductionAudio = () => {
  const [audioEnded, setAudioEnded] = useState(false);
  const audio = useRef<React.RefObject<HTMLAudioElement> | null>(null);

  return (
    <ContentWrapper>
      <PageTitle>SUPER! HÖRT EUCH JETZT DAS EINFÜHRUNGS-AUDIO AN</PageTitle>

      <Box mt={14.4} mb={6.5}>
        <ReactAudioPlayer
          src={audios.intro}
          controls
          controlsList="nodownload"
          onEnded={() => setAudioEnded(true)}
          ref={(element) => {
            if (element !== null) {
              audio.current = element.audioEl;
            }
          }}
        />
      </Box>

      <Box py={6.4}>
        <BodyText>
          Kein Ton? Klicke{" "}
          <BodyText
            sx={{ display: "inline", color: "#087FD4" }}
            onClick={() => audio?.current?.current?.play()}
            component="span"
          >
            hier
          </BodyText>
        </BodyText>
      </Box>

      <Box mb={6.4}>
        <StyledButton
          to={AppRoutes.select_stage}
          variant="contained"
          disabled={!audioEnded}
          component={Link}
        >
          ZUM POLIZEI-SERVER
        </StyledButton>
      </Box>
    </ContentWrapper>
  );
};

export const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 1),
  textAlign: "center",
}));

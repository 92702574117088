import { alpha, styled, Typography } from "@mui/material";

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: alpha(theme.palette.text.primary, 0.87),
  fontWeight: 500,
  fontSize: "2.4rem",
  textAlign: "center",
  padding: theme.spacing(0, 1),
  lineHeight: "4rem",
  letterSpacing: "0.04rem",
}));

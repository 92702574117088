import React from "react";

import { selectCurrentStage } from "@/store/slices/stages.slice";
import { useAppSelector } from "@/hooks/common";

import { TextInputView } from "./text.input.view";
import { SelectPersonView } from "./select.person.view";
import { MapView } from "./map.view";

export const StageView = () => {
  const currentStage = useAppSelector(selectCurrentStage)!;

  if (currentStage.answer_type === "text_input") {
    return <TextInputView />;
  }

  if (currentStage.answer_type === "select_person") {
    return <SelectPersonView />;
  }

  if (currentStage.answer_type === "map") {
    return <MapView />;
  }

  return <>Invalid Stage</>;
};

import {
  Box,
  createTheme,
  Select,
  styled,
  Theme,
  Typography,
} from "@mui/material";
import { StyleUtils } from "@/constants/style.utils";

export const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 1),
}));

export const PhoneImage = styled("img")(({ theme }) => ({
  width: "15rem",
  display: "block",
  margin: theme.spacing(6, "auto"),
}));

export const PhoneNumberInputWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(6.4, 0),
  display: "flex",
  columnGap: theme.spacing(1),
}));

// Theme for Custom Select
export const theme = (theme: Theme) =>
  createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      mode: "light",
      background: {
        default: "#fff",
        paper: "#fff",
      },
    },
  });

export const CustomSelect = styled(Select)(({ theme }) => ({
  height: "4.8rem",
  backgroundColor: theme.palette.common.white,
  borderRadius: StyleUtils.border.radius(2),

  ".MuiSvgIcon-root": {
    color: "rgba(0, 0, 0, 0.54)",
    right: "1rem",
  },
}));

export const MenuItemBox = styled(Box)(({ theme }) => ({
  fontWeight: "700",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(0.6),
}));

export const MenuItemImage = styled("img")(({ theme }) => ({
  width: "2.6rem",
  height: "1.8rem",
}));

export const MenuItemText = styled(Typography)(({ theme }) => ({
  fontSize: StyleUtils.fonts.size(4),
  marginTop: theme.spacing(0.2),
  fontWeight: 700,
  color: theme.palette.grey[600],
})) as typeof Typography;

import { Box, styled } from "@mui/material";

import { PageTitle } from "@/components/page.title";
import { StyleUtils } from "@/constants/style.utils";

export const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6.4, 1),
  minHeight: "100%",
}));

export const QuestionText = styled(PageTitle)(({ theme }) => ({
  border: `1px solid ${theme.palette.common.white}`,
  borderRadius: StyleUtils.border.radius(1),
  padding: theme.spacing(1),
}));

export const AppRoutes = {
  home: "/",
  select_difficulty: "/select-difficulty",
  phone_number: "/phone-number",
  introduction_audio: "/introduction-audio",
  select_stage: "/select-stage",
  stage: "/stage",
  messages: "/messages",
  stages_complete: "/stages-complete",
  ratings: "/ratings",
};

import { alpha, Box, styled } from "@mui/material";

import { StyleUtils } from "@/constants/style.utils";

export const ContentWrapper = styled(Box)(({ theme }) => ({
  maxWidth: "36.4rem",
  padding: theme.spacing(3.2, 1),
  margin: theme.spacing(0, "auto"),
  minHeight: "100%",
}));

export const PoliceServerCredentials = styled(Box)(({ theme }) => ({
  textAlign: "center",
  fontSize: StyleUtils.fonts.size(3.5),
  color: alpha(theme.palette.common.white, 0.6),
  margin: theme.spacing(0, "auto"),
  padding: theme.spacing(2, 0),
  border: `1px solid ${alpha(theme.palette.common.white, 0.6)}`,
  borderRadius: StyleUtils.border.radius(1),
  boxShadow: `0px 0px 10px 1px ${alpha(theme.palette.common.black, 0.6)}`,

  "*": {
    margin: "0rem",
  },

  h3: {
    marginBottom: theme.spacing(0.5),
  },
}));

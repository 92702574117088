import React from "react";
import { Link } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import { css } from "@emotion/react";
import Scrollbars from "react-custom-scrollbars-2";

import {
  selectSelectedDifficulty,
  setSelectedDifficulty,
} from "@/store/slices/local.storage.slice";
import { useAppDispatch, useAppSelector } from "@/hooks/common";
import { SelectedDifficulty } from "@/models/shared";
import DifficultyCard from "@/components/difficulty.card";
import { StyledButton } from "@/components/button";
import { PageTitle } from "@/components/page.title";
import { AppRoutes } from "@/constants/app.routes";

import { difficulties } from "./data";
import { CardsWrapper, ContentWrapper } from "./styles";

export const SelectDifficulty = () => {
  const theme = useTheme();
  const selectedDifficulty = useAppSelector(selectSelectedDifficulty);
  const dispatch = useAppDispatch();

  const handleSelectDifficulty = async (
    selectedDifficultyLevel: SelectedDifficulty
  ) => {
    dispatch(
      setSelectedDifficulty({ selectedDifficulty: selectedDifficultyLevel })
    );
  };

  return (
    <ContentWrapper>
      <PageTitle>WÄHLE DIE SCHWIERIGKEIT</PageTitle>

      <Scrollbars
        style={{ height: "32rem" }}
        css={css({
          margin: theme.spacing(8, 0),
        })}
      >
        <CardsWrapper>
          {difficulties.map((difficulty) => {
            return (
              <DifficultyCard
                key={difficulty.level}
                difficulty={difficulty}
                isSelected={selectedDifficulty === difficulty.level}
                onClick={handleSelectDifficulty}
              />
            );
          })}
        </CardsWrapper>
      </Scrollbars>

      <Box textAlign={"center"}>
        <StyledButton
          disabled={!selectedDifficulty}
          variant="contained"
          to={AppRoutes.phone_number}
          component={Link}
        >
          WEITER
        </StyledButton>
      </Box>
    </ContentWrapper>
  );
};

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import ReactAudioPlayer from "react-audio-player";

import { setLogDoc } from "@/firebase/logs";
import { selectUserState } from "@/store/slices/user.slice";
import { useAppSelector } from "@/hooks/common";
import { CoverImage } from "@/components/cover.image";
import { Impressum } from "@/components/impressum";
import { PageTitle } from "@/components/page.title";
import { StyledButton } from "@/components/button";
import { currentTimeInSeconds } from "@/utils/common";
import { AppRoutes } from "@/constants/app.routes";
import { audios } from "@/constants/audios";

import { ContentWrapper } from "./styles";

export const StagesComplete: React.FC = () => {
  const user = useAppSelector(selectUserState).user!;

  const [hasFinishedAudio, setHasFinishedAudio] = useState(false);

  function handleAudioComplete() {
    setHasFinishedAudio(true);
    setLogDoc(user.phone_number, {
      type: "finished_outro_audio",
      timestamp: currentTimeInSeconds(),
    });
  }

  return (
    <ContentWrapper>
      <CoverImage />

      <Box mt={8}>
        <PageTitle>HÖRE DIR NUN DAS ABSCHLUSS AUDIO AN:</PageTitle>
      </Box>

      <Box mt={8}>
        <ReactAudioPlayer
          src={audios.stages_complete}
          controls
          controlsList="nodownload"
          style={{ width: "100%" }}
          onEnded={handleAudioComplete}
        />
      </Box>

      <Box mt={8} textAlign={"center"}>
        {hasFinishedAudio && (
          <StyledButton
            to={AppRoutes.ratings}
            variant="contained"
            component={Link}
          >
            Weiter
          </StyledButton>
        )}
      </Box>

      <Box mt={8} mb={3.2} textAlign="center">
        <Impressum />
      </Box>
    </ContentWrapper>
  );
};

import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { userSlice } from "./slices/user.slice";
import localStorageReducer, {
  localStorageSlice,
} from "./slices/local.storage.slice";
import { stagesSlice } from "./slices/stages.slice";

export const store = configureStore({
  reducer: {
    [userSlice.name]: userSlice.reducer,
    [localStorageSlice.name]: localStorageReducer, // persisted
    [stagesSlice.name]: stagesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` type from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Infer the `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;

import { useEffect, useState } from "react";
import { useStore } from "react-redux";

import { RootState } from "@/store";
import { selectAllStages, setAllStages } from "@/store/slices/stages.slice";
import { useAppDispatch } from "@/hooks/common";
import { LoadingState } from "@/models/shared";
import { StageList } from "@/models/stage";

import { getAllStagesData } from ".";

export const useGetAllStages = () => {
  const [stages, setStages] = useState<StageList>([]);
  const [loading, setLoading] = useState<LoadingState>("idle");

  const store = useStore();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const allStages = selectAllStages(store.getState() as RootState);

    if (allStages.length > 0) {
      setStages(allStages);
      setLoading("loaded");
    } else {
      setLoading("loading");
      getAllStagesData()
        .then((stages) => {
          dispatch(setAllStages(stages));
          setStages(stages);
          setLoading("loaded");
        })
        .catch(() => setLoading("failed"));
    }
  }, [dispatch, store]);

  return { stages, loading };
};

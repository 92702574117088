import React from "react";
import { Outlet } from "react-router-dom";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { css } from "@emotion/react";
import { useWindowSize } from "usehooks-ts";
import Scrollbars from "react-custom-scrollbars-2";

import { StyleUtils } from "@/constants/style.utils";

export const MobileLayout: React.FC = () => {
  const theme = useTheme();
  const belowMobileWrapper = useMediaQuery(
    theme.breakpoints.down("mobile_wrapper")
  );
  const windowSize = useWindowSize();

  return (
    <Box
      sx={(theme) => ({
        height: windowSize.height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: theme.shadows[4],
      })}
    >
      <Scrollbars
        style={{
          width: belowMobileWrapper ? "100%" : "42rem",
          height: belowMobileWrapper
            ? "100%"
            : `${(windowSize.height - 60) / 10}rem`,
        }}
        css={css({
          maxHeight: belowMobileWrapper ? "100%" : "96rem",
          borderRadius: belowMobileWrapper
            ? "0px"
            : StyleUtils.border.radius(3),
          backgroundColor: theme.palette.mobile_wrapper.main,
        })}
      >
        <Outlet />
      </Scrollbars>
    </Box>
  );
};

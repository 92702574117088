import React from "react";

import { Difficulty } from "@/pages/select.difficulty/data";
import { BodyText } from "@/components/body.text";
import { TickImage } from "@/components/tick.image";
import { SelectedDifficulty } from "@/models/shared";

import { ContentWrapper, DifficultyImage, CardTitle } from "./styles";

interface DifficultyCardProps {
  difficulty: Difficulty;
  isSelected: boolean;
  onClick: (selectedDifficulty: SelectedDifficulty) => void;
}

const DifficultyCard: React.FC<DifficultyCardProps> = ({
  difficulty,
  isSelected,
  onClick,
}) => {
  return (
    <ContentWrapper
      isSelected={isSelected}
      onClick={() => onClick(difficulty.level)}
    >
      {isSelected && <TickImage />}

      <CardTitle component={"h3"}>{difficulty.title}</CardTitle>

      <DifficultyImage src={difficulty.image} alt={difficulty.level} />

      <BodyText textAlign={"center"}>{difficulty.body}</BodyText>
    </ContentWrapper>
  );
};

export default DifficultyCard;

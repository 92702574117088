import { useEffect, useState } from "react";

import { LoadingState } from "@/models/shared";
import { PersonList } from "@/models/person";

import { getAllPersons } from ".";

export const usePersons = () => {
  const [persons, setPersons] = useState<PersonList>([]);
  const [loading, setLoading] = useState<LoadingState>("idle");

  useEffect(() => {
    setLoading("loading");
    getAllPersons()
      .then((persons) => {
        setPersons(persons);
        setLoading("loaded");
      })
      .catch(() => setLoading("failed"));
  }, []);

  return { persons, loading };
};
